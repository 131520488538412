/* eslint-disable react/prop-types */
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../Constant/Global";

const MainContext = createContext();

export const MainContextProvider = ({ children }) => {
  const userDetails = sessionStorage.getItem("info");
  const useInfo = JSON.parse(userDetails);

  // useEffect(() => {
  //     if (userInfo?.subscription_name === '' || userInfo?.subscription_name === null) {
  //         localStorage.setItem("planShow", "true");
  //     } else {
  //         localStorage.setItem("planShow", "false");
  //     }
  // }, [userInfo])

  const planShow = localStorage.getItem("planShow") === "true";
  const [plansOpen, setPlansOpen] = useState(planShow);
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [notificationOpen, setNotificationOpen] = useState(false);

  const handleGetNotificationData = async () => {
    try {
      const response = await axios.get(
        API_BASE_URL + `vendor/notification/${useInfo.id}`,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status) {
        setNotifications(response.data.data);
        // Filtering notifications with is_read === "N"
        const unreadNotifications = response.data.data.filter(
          (notification) => notification.is_read === "N"
        );

        // Setting the unread count
        setUnreadCount(unreadNotifications.length);
        // Auto-select the first category
      } else {
        setNotifications([]);
      }
    } catch (error) {
      console.log("error occured fetching data", error);
    }
  };

  useEffect(() => {
    if (useInfo) {
      handleGetNotificationData();
    }
  }, []);

  const handleRefreshNotificationData = async () => {
    await handleGetNotificationData();
  };

  // update notification read status

  const handleNotificationUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("is_read", "Y");

      const response = await axios.put(
        API_BASE_URL + `vendor/notification/update/${useInfo.id}`,
        formData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status) {
        setUnreadCount(0);
      } else {
        setUnreadCount(0);
      }
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  return (
    <MainContext.Provider
      value={{
        plansOpen,
        setPlansOpen,
        unreadCount,
        setUnreadCount,
        notifications,
        setNotifications,
        handleNotificationUpdate,
        handleRefreshNotificationData,
        notificationOpen,
        setNotificationOpen,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export const useMainContext = () => {
  return useContext(MainContext);
};

export default MainContext;
