import React, { useEffect, useRef } from "react";
import "./notification.css";
import axios from "axios";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
// import { MdDelete } from "react-icons/md";
import { useMainContext } from "../../context/MainContext";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const NotificationModal = ({ notificationOpen, setNotificationOpen }) => {
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const { notifications, handleRefreshNotificationData } = useMainContext();
  //   get notification

  const userDetails = sessionStorage.getItem("info");
  const useInfo = JSON.parse(userDetails);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setNotificationOpen(false); // Call the onClose function passed as a prop
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the modal
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!notificationOpen) return null; // Don't render the modal if it's not open

  const formatTimeAgo = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const seconds = Math.floor((now - date) / 1000);

    if (seconds < 60) {
      return "just now";
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (seconds < 172800) {
      // 2 days
      return "yesterday";
    } else {
      const days = Math.floor(seconds / 86400);
      return `${days} day${days > 1 ? "s" : ""} ago`;
    }
  };

  // update notification read status

  const handleClearAll = async () => {
    try {
      const response = await axios.delete(
        API_BASE_URL + `vendor/notification/delete-all/${useInfo.id}`,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status) {
        toast.success(response.data.message);
        setTimeout(() => {
          handleRefreshNotificationData();
        }, 1000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("errror occured fetching data", error);
    }
  };

  return (
    <div className="noti-modal">
      <div className="noti-modal-content" ref={modalRef}>
        <div className="noti-modal-header">
          <h2>Notifications</h2>
          {notifications.length > 0 && (
            <button className="noti-clear-all-btn" onClick={handleClearAll}>
              Clear All
            </button>
          )}
        </div>
        <div className="noti-modal-body">
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <div
                key={notification.id}
                className="notification-item"
                onClick={() => {
                  navigate(`/leads/${notification.id}`);
                  setNotificationOpen(false);
                }}
              >
                <p>{notification.comment}</p>
                <span>{formatTimeAgo(notification.created_at)}</span>
                {/* <button
                  className="noti-delete-btn"
                  onClick={() => deleteNotification(notification.id)}
                >
                  <MdDelete />
                </button> */}
              </div>
            ))
          ) : (
            <p>No notifications available</p>
          )}
        </div>
      </div>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </div>
  );
};

export default NotificationModal;
